import M$control$PredefinedZoomControl from './facade/js/predefinedzoomcontrol';
import M$control$ViewHistoryControl from './facade/js/viewhistorycontrol';
import M$plugin$ViewManagement from './facade/js/viewmanagement';
import M$control$ViewManagementControl from './facade/js/viewmanagementcontrol';
import M$control$ZoomExtentControl from './facade/js/zoomextentcontrol';
import M$control$ZoomPanelControl from './facade/js/zoompanelcontrol';
import M$impl$control$PredefinedZoomControl from './impl/ol/js/predefinedzoomcontrol';
import M$impl$control$ViewHistoryControl from './impl/ol/js/viewhistorycontrol';
import M$impl$control$ViewManagementControl from './impl/ol/js/viewmanagement';
import M$impl$control$ZoomExtentControl from './impl/ol/js/zoomextentcontrol';
import M$impl$control$ZoomPanelControl from './impl/ol/js/zoompanelcontrol';

if (!window.M.control) window.M.control = {};
if (!window.M.plugin) window.M.plugin = {};
if (!window.M.impl) window.M.impl = {};
if (!window.M.impl.control) window.M.impl.control = {};
window.M.control.PredefinedZoomControl = M$control$PredefinedZoomControl;
window.M.control.ViewHistoryControl = M$control$ViewHistoryControl;
window.M.plugin.ViewManagement = M$plugin$ViewManagement;
window.M.control.ViewManagementControl = M$control$ViewManagementControl;
window.M.control.ZoomExtentControl = M$control$ZoomExtentControl;
window.M.control.ZoomPanelControl = M$control$ZoomPanelControl;
window.M.impl.control.PredefinedZoomControl = M$impl$control$PredefinedZoomControl;
window.M.impl.control.ViewHistoryControl = M$impl$control$ViewHistoryControl;
window.M.impl.control.ViewManagementControl = M$impl$control$ViewManagementControl;
window.M.impl.control.ZoomExtentControl = M$impl$control$ZoomExtentControl;
window.M.impl.control.ZoomPanelControl = M$impl$control$ZoomPanelControl;
